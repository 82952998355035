import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'styles/theme';

import Input from './Input';
import Select from './Select';
import Text from 'components/Text';

import { cardStyle } from 'components/Card';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import useMediaQuery from 'hooks/useMediaQuery';

const Wrapper = styled.div`
  padding-top: 57px;
  grid-column: 1/-1;
`;

const StyledMeasurements = styled.div`
  ${cardStyle}
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 34px 20px 49px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 auto;
  justify-content: center;
  ${media.ns} {
    flex-direction: row;
  }
`;

const Title = styled(Text)`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 18px;
  margin: 0 auto 25px;
  text-align: center;
`;

const InputWrapper = styled.div`
  display: flex;
  margin: 10px 0;
  width: 100%;
  ${media.ns} {
    margin: 0;
  }
`;

const MobileSelect = styled(Select)`
  margin-left: 10px;
  min-width: 90px;
  width: 90px;
  ${media.ns} {
    display: none;
  }
`;

function Measurements({ t, ...rest }) {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const [length, setLength] = useQueryParam('length', NumberParam);
  const [width, setWidth] = useQueryParam('width', NumberParam);
  const [height, setHeight] = useQueryParam('height', NumberParam);
  const [unit, setUnit] = useQueryParam('unit', StringParam);

  React.useEffect(() => {
    if (!length && !width && !height && !unit) {
      setLength(0);
      setWidth(0);
      setHeight(0);
      setUnit('feet');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      <Title color="black">{t('roomSize:measureTitle')}</Title>
      <StyledMeasurements {...rest}>
        <InputContainer>
          <InputWrapper>
            <Input
              label={t('roomSize:length')}
              onChange={(e) => setLength(e.target.value)}
              placeholder={10}
              value={length || ''}
            />
            <MobileSelect
              onChange={(e) => setUnit(e.target.value)}
              value={unit}
              t={t}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              label={t('roomSize:width')}
              onChange={(e) => setWidth(e.target.value)}
              placeholder={10}
              value={width || ''}
            />
            <MobileSelect
              onChange={(e) => setUnit(e.target.value)}
              value={unit}
              t={t}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              label={t('roomSize:height')}
              onChange={(e) => setHeight(e.target.value)}
              placeholder={10}
              value={height || ''}
            />
            <MobileSelect
              onChange={(e) => setUnit(e.target.value)}
              value={unit}
              t={t}
            />
          </InputWrapper>
          {!isMobile && (
            <Select
              onChange={(e) => setUnit(e.target.value)}
              value={unit}
              t={t}
            />
          )}
        </InputContainer>
      </StyledMeasurements>
    </Wrapper>
  );
}

Measurements.propTypes = {
  t: PropTypes.any.isRequired,
};

Measurements.defaultProps = {};

export default Measurements;
