import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const arrowSize = '12px';
const textBreakpoint = 915;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.gray};
`;

const StyledNavItem = styled.div`
  align-items: center;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.green : theme.colors.gray};
  border: none;
  color: ${({ active, theme }) =>
    active ? theme.colors.black : theme.colors.white};
  font-size: 12px;
  display: flex;
  height: 20px;
  justify-content: center;
  position: relative;
  text-transform: uppercase;
  ${({ theme }) => theme.fonts.dinBold}
  @media screen and (min-width: ${textBreakpoint + 1}px) {
    height: 44px;
    ${({ hasArrow }) =>
      hasArrow &&
      `clip-path: polygon( -1% -1%, calc(100% - ${arrowSize}) 0%, 100% 50%, calc(100% - ${arrowSize}) 100%, 0% 100%);`}
    }
  }

`;

const TextWrapper = styled.span`
  transform: translateY(1px);
  @media screen and (max-width: ${textBreakpoint}px) {
    display: none;
  }
`;

function NavItem({ children, ...rest }) {
  return (
    <Wrapper>
      <StyledNavItem {...rest}>
        <TextWrapper>{children}</TextWrapper>
      </StyledNavItem>
    </Wrapper>
  );
}

NavItem.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  hasArrow: PropTypes.bool.isRequired,
};

NavItem.defaultProps = {};

export default NavItem;
