import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

const Label = styled.label`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 15px;
  margin-bottom: 8px;
  text-transform: capitalize;
`;

export const StyledInput = styled.input`
  border: 1px solid ${({ theme }) => theme.colors.mediumGray};
  color: ${({ theme }) => theme.colors.altBlack};
  font-size: 15px;
  height: 41px;
  outline: 0;
  padding: 0 11px;
  transition: border 200ms ease-out;
  ::placeholder {
    color: ${({ theme }) => theme.colors.mediumGray};
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.colors.darkGray};
  }
  -moz-appearance: textfield;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

function Input({ label, ...rest }) {
  return (
    <InputWrapper>
      <Label>{label}</Label>
      <StyledInput type="number" {...rest} />
    </InputWrapper>
  );
}

Input.propTypes = {};

Input.defaultProps = {};

export default Input;
