import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'styles/theme';

import Card from '../Card';

export const StyledCardArea = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(170px, 180px));
  grid-gap: 16px;
  margin: 40px auto 90px;
  max-width: 860px;
  ${media.ns} {
    grid-gap: 32px;
    grid-template-columns: repeat(
      ${({ columns }) => (columns ? columns : 3)},
      minmax(180px, 264px)
    );
    margin: 60px auto;
  }
`;

function CardArea({
  columns,
  handleSelect,
  multiselect,
  selectedValue,
  values,
  ...rest
}) {
  function getActive(cardIndex) {
    if (multiselect) {
      return selectedValue.includes(cardIndex);
    }
    return cardIndex === selectedValue;
  }
  return (
    <StyledCardArea columns={columns} {...rest}>
      {values.map((el) => (
        <Card
          active={getActive(el.value)}
          key={el.value}
          onClick={() => handleSelect(el.value)}
        >
          {el.content}
        </Card>
      ))}
    </StyledCardArea>
  );
}

CardArea.propTypes = {
  columns: PropTypes.number,
  multiselect: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  values: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.any.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
};

CardArea.defaultProps = {
  columns: 3,
  multiselect: false,
  selectedValue: null,
  values: [],
};

export default CardArea;
