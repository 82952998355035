import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { StyledInput } from './Input';
import DownArrow from './DownArrow';

const SelectWrapper = styled(StyledInput)`
  align-self: flex-end;
  background-color: white;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0;
  min-height: 43px;
  padding: 0;
  position: relative;
  min-width: 90px;
  :focus-within {
    border-color: ${({ theme }) => theme.colors.darkGray};
  }
  select {
    appearance: none;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.altBlack};
    border: none;
    cursor: inherit;
    margin: 0;
    padding: 0 11px;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    outline: none;
    line-height: inherit;
    height: 100%;
  }
  select::-ms-expand {
    display: none;
  }
`;

const StyledDownArrow = styled(DownArrow)`
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
  right: 8px;
`;

const Select = ({ className, style, t, ...rest }) => {
  return (
    <SelectWrapper as="div" className={className} style={style}>
      <select {...rest}>
        <option value="feet">{t('roomSize:feet')}</option>
        <option value="meters">{t('roomSize:meters')}</option>
      </select>
      <StyledDownArrow height={8} />
    </SelectWrapper>
  );
};
Select.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  style: PropTypes.object,
};

Select.defaultProps = {
  className: '',
  description: null,
  label: null,
  required: false,
  style: {},
};

export default Select;
