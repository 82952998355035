import React from 'react';
import { useQueryParam, StringParam } from 'use-query-params';

function useShowApplications() {
  const [showApplications, setShowApplications] = React.useState(false);
  const [selectedValue] = useQueryParam('micPlacement', StringParam);

  React.useEffect(() => {
    setShowApplications(selectedValue === 'ceiling');
  }, [selectedValue]);

  return showApplications;
}

export default useShowApplications;
