import * as React from 'react';
// import PropTypes from 'prop-types';

function DownArrow(props) {
  return (
    <svg
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1 8.5L8 1.5L15 8.5" stroke="#444444" strokeWidth="2" />
    </svg>
  );
}

DownArrow.propTypes = {};

DownArrow.defaultProps = {};

export default DownArrow;
