import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { media } from 'styles/theme';

import Card, { CardTitle, CardDescription } from 'components/Card';
import Header from 'components/Header';
import Layout from 'components/Layout';
import Measurements from 'pageComponents/roomSize/Measurements';
import { StyledCardArea } from 'components/CardArea';
import Title from 'components/Title';

import { getSearch, gtmRoomSize } from 'utils/helpers';
import routes from 'utils/routes';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const CardArea = styled(StyledCardArea)`
  grid-gap: 16px;
  ${media.ns} {
    grid-gap: ${({ hasSelection }) => (hasSelection ? 16 : 32)}px 32px;
  }
`;

function RoomSize(props) {
  const { t } = useTranslation(['common', 'nav', 'roomSize']);
  const [selectedValue, setSelectedValue] = useQueryParam(
    'roomSize',
    StringParam
  );
  const [height] = useQueryParam('height', NumberParam);
  const [length] = useQueryParam('length', NumberParam);
  const [width] = useQueryParam('width', NumberParam);

  const navProps = {
    backButton: {
      to: `${routes.home}${getSearch()}`,
    },
    nextButton: {
      disabled: isDisabled(),
      to: `${routes.seating}${getSearch()}`,
    },
  };

  React.useEffect(() => {
    return () => {
      gtmRoomSize();
    };
    // eslint-disable-next-line
  }, []);

  const roomSizeOptions = [
    { value: 'small', desc: '1-4' },
    { value: 'medium', desc: '5-9' },
    { value: 'large', desc: '6-15' },
    { value: 'xlarge', desc: '15-50' },
    { value: 'xxlarge', desc: '50-100' },
    { value: 'other' },
  ];

  function isDisabled() {
    if (selectedValue) {
      return false;
    }
    if (length !== null && width !== null && height !== null) {
      return false;
    }
    return true;
  }

  function handleSelect(val) {
    setSelectedValue(val);
  }

  function getTitle(val) {
    if (val === 'other') {
      return t('common:other');
    }
    return t(`roomSize:${val}`);
  }

  return (
    <Layout navProps={navProps}>
      <Header pageNumber={2} t={t} />
      <Title
        subTitle={t('common:globalSubTitle')}
        title={t('roomSize:title')}
        tooltip={t('roomSize:titleTooltip')}
      />
      <CardArea columns={3}>
        {roomSizeOptions.map((option, i) => (
          <Card
            active={option.value === selectedValue}
            key={option.value}
            onClick={() => handleSelect(option.value)}
          >
            <CardTitle>{getTitle(option.value)}</CardTitle>
            {option.desc && (
              <CardDescription>
                {option.desc} {t('roomSize:people')}
              </CardDescription>
            )}
          </Card>
        ))}
        <Measurements t={t} />
      </CardArea>
    </Layout>
  );
}

RoomSize.propTypes = {};

RoomSize.defaultProps = {};
export default RoomSize;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["common", "nav", "roomSize"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
