// in order of page progression
const routes = {
  home: '/',
  videoConferencing: '/',
  sound: '/sound-reinforcement',
  codec: '/codec',
  roomSize: '/room-size',
  seating: '/seating',
  microphonePlacement: '/microphone-placement',
  applications: '/applications',
  recommendations: '/recommendations',
};

export default routes;
