import * as React from 'react';
import styled, { css } from 'styled-components';
import { media } from 'styles/theme';

import ActiveIcon from './ActiveIcon';
import Text from 'components/Text';

const StyledActiveIcon = styled(ActiveIcon)`
  left: -4px;
  position: absolute;
  top: -4px;
`;

export const cardStyle = css`
  background-color: ${({ theme }) => theme.colors.white};
  box-sizing: border-box;
  border: 4px solid;
  border-color: ${({ $active, theme }) =>
    $active ? theme.colors.green : theme.colors.white};
  box-shadow: 0px 1px 6px 0px #00000040;
`;

const StyledCard = styled.div`
  ${cardStyle}
  align-items: center;
  box-shadow: 0px 1px 6px 0px #00000040;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  min-height: 116px;
  justify-content: center;
  padding: 22px 12px;
  position: relative;
  ${media.ns} {
    height: 175px;
    padding: 16px 31px;
  }
`;

export const CardTitle = styled(Text).attrs((props) => ({
  size: 'f4',
  font: 'ostrichBold',
}))`
  text-align: center;
  text-transform: uppercase;
  word-break: break-word;
`;

export const CardDescription = styled(Text)`
  &&& {
    color: ${({ theme }) => theme.colors.gray};
    font-size: 13px;
    line-height: 16px;
    margin-top: 6px;
    text-align: center;
    ${media.ns} {
      font-size: 15px;
      line-height: 18px;
      margin-top: 4px;
    }
  }
`;

function Card(props) {
  // MTS - We're converting over to using component state for the active state
  // on cards because we're getting an SSR error when a page refreshes when
  // there are active cards on that page.
  const propsActive = props?.active;
  const [active, setActive] = React.useState(false);

  React.useEffect(() => {
    setActive(propsActive);
  }, [propsActive]);

  const { children, ...rest } = props;
  const cardProps = { ...rest, $active: active };
  return (
    <StyledCard {...cardProps}>
      {cardProps.$active && <StyledActiveIcon />}
      {props?.children}
    </StyledCard>
  );
}

Card.propTypes = {};

Card.defaultProps = {};

export default Card;
