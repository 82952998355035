import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import NavItem from './NavItem';
import useShowApplications from 'hooks/useShowApplications';

const StyledHeader = styled.nav`
  display: grid;
  grid-template-columns: repeat(
    ${({ $showApplications }) => ($showApplications ? 5 : 4)},
    1fr
  );
`;

function Header({ pageNumber, t }) {
  const showApplications = useShowApplications();

  return (
    <StyledHeader $showApplications={showApplications}>
      <NavItem active={pageNumber >= 1} hasArrow={pageNumber === 1}>
        {t('nav:videoConferencing')}
      </NavItem>
      <NavItem active={pageNumber >= 2} hasArrow={pageNumber === 2}>
        {t('nav:roomSize')}
      </NavItem>
      <NavItem active={pageNumber >= 3} hasArrow={pageNumber === 3}>
        {t('nav:seating')}
      </NavItem>
      <NavItem
        active={pageNumber >= 4}
        hasArrow={pageNumber === 4 && showApplications}
      >
        {t('nav:microphonePlacement')}
      </NavItem>
      {showApplications && (
        <NavItem active={pageNumber >= 5} hasArrow={false}>
          {t('nav:applications')}
        </NavItem>
      )}
    </StyledHeader>
  );
}

Header.propTypes = {
  pageNumber: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

Header.defaultProps = {};

export default Header;
